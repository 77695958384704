import React, { useRef } from 'react'
import { graphql } from 'gatsby'
import { Layout, Search, PostGrid } from '../../components'
import CollectionHero, { Item } from '../../components/CollectionHero'
import { Seo } from '../../components/base'
import { LayoutBackground } from '../../components/shared'
import { getLocale } from '../../utils/localeUtils'

const PostPage = ({ data, location, pageContext }) => {
  const posts = data.sanityPosts.nodes
  const {
    title,
    overviewHeadline,
    postTeaserLeft,
    postTeaserRight,
    _rawNoSearchResultsText,
    seo
  } = data.sanityPostPage.nodes[0]

  const languages = { pageContext }

  const layoutBackgroundHeaderComponent = (
    <h1 className="mb-14 md:mb-24">{overviewHeadline}</h1>
  )
  const searchResultRef = useRef()
  const locale = getLocale()
  return (
    <>
      <Layout location={location} title={''} translations={languages}>
        <Seo
          title={seo?.metaTitle}
          description={seo?.metaDescription}
          image={seo?.ogImage?.asset?.url}
        />
        <CollectionHero>
          <Item
            contentItem={postTeaserLeft}
            whiteFont
            headlineTextShadow
            leftItem
          />
          <Item
            contentItem={postTeaserRight}
            whiteFont
            headlineTextShadow
            rightItem
          />
        </CollectionHero>
        <LayoutBackground headerComponent={layoutBackgroundHeaderComponent}>
          <div
            ref={searchResultRef}
            className="flex flex-wrap justify-between relative">
            <Search
              showToTopButton
              noInitialRender
              index="post"
              initialItems={posts}
              count={data.sanityPosts.totalCount}
              typeOfCount="Artikel"
              searchListHeight={searchResultRef?.current?.offsetHeight}
              locale={locale}>
              <PostGrid
                items={posts}
                noSearchResultsText={_rawNoSearchResultsText}
              />
            </Search>
          </div>
        </LayoutBackground>
      </Layout>
    </>
  )
}

export default PostPage

export const postQuery = graphql`
  query ($language: String! = "de_de") {
    sanityPostPage: allSanityPostPage(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        title
        seo {
          ...SeoQuery
        }
        overviewHeadline
        _rawNoSearchResultsText
        postTeaserLeft {
          ...TeaserContentQuery
        }
        postTeaserRight {
          ...TeaserContentQuery
        }
      }
    }
    sanityPosts: allSanityPost(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
      limit: 12
      sort: { fields: [_updatedAt], order: DESC }
    ) {
      totalCount
      nodes {
        title
        subtitle
        basePath
        category
        desc
        category
        slug: slug {
          current
        }
        image {
          ...ImageObjectQuery
        }
        previewImage {
          ...ImageObjectQuery
        }
        icon {
          ...ImageObjectQuery
        }
        titleIcon {
          ...ImageObjectQuery
        }
        basePath
        category
      }
    }
  }
`
